interface KeyValueCardProps {
  title: number | string;
  value: number | string;
}

const KeyValueCard = ({ title, value }: KeyValueCardProps) => {
  return (
    <div className="">
      <div className="text-gray-dark-3 text-sm">{title}</div>
      <div className="text-white font-extrabold break-words">{value}</div>
    </div>
  );
};

export default KeyValueCard;
