import React from "react";
import "./App.css";
import BalanceSearch from "./components/BalanceSearch";

function App() {
  return (
    <div className="flex justify-center flex-col text-center md:text-left md:flex-row md:h-screen">
      <BalanceSearch></BalanceSearch>
    </div>
  );
}

export default App;
