import KeyValueCard from "./KeyValueCard";

interface BalanceDetailsProps {
  balances: Balance[];
}

export interface Balance {
  title: string;
  amount: number;
}

const BalanceDetails = ({ balances }: BalanceDetailsProps) => {

  const formatBalance = (balance:number) => {
    return `${parseFloat((balance).toFixed(10))} ZTG`
  }
  
  return (
    <div>
      {balances?.map((balance,index) => (
        <KeyValueCard key={index} title={balance.title} value={formatBalance(balance.amount)}/>
      ))}
    </div>
  );
};

export default BalanceDetails;
