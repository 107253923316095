import { decodeAddress } from "@polkadot/keyring";

const calculateTotalReward = (
  address: string,
  rewardsData: { address: string; reward: number }[]
) => {
  return rewardsData
    .filter((reward) => {
      try {
        return decodeAddress(reward.address, true).toString() === decodeAddress(address, true).toString()
      } catch (err) {
        return false;
      }
    })
    .reduce((a, b) => a + b.reward, 0);
};

export default calculateTotalReward;
