import { encodeAddress } from "@polkadot/keyring";
import { useState } from "react";
import betaCampaignData from "../data/beta-rewards.json";
import derbyGleamData from "../data/derby-gleam.json";
import derbyNodesData from "../data/derby-nodes.json";
import derbyTestnetData from "../data/derby-testnet.json";
import formCorrectionData from "../data/form-correction.json";
import calculateTotalReward from "../utils/calculate-total";
import AddressSearch from "./AddressSearch";
import BalanceDetails, { Balance } from "./BalanceDetails";
import KeyValueCard from "./KeyValueCard";
import logo from "../images/zeitgeist-logo.png";

// Crowdloan Rewards
import crowdloanBase from "../data/crowdloan-base.json";
import crowdloanEarlyBird from "../data/crowdloan-early-bird.json";
import crowdloanEarlySupporter from "../data/crowdloan-early-supporter.json";
import crowdloanReferral from "../data/crowdloan-referral.json";
import crowdloanSignup from "../data/crowdloan-signup.json";
// Bifrost Contributions
import bifrostRewards from "../data/bifrost-base.json";
import bifrostRefRewards from "../data/bifrost-refs.json";

// Shape the Future Rewards
import shapeTheFuturePurchases from "../data/purchases.json";

// Misc Rewards
import miscRewards from "../data/extras.json";
import seerForChinaRewards from "../data/seer-for-china.json";

const BalanceSearch = () => {
  const [balances, setBalances] = useState<Balance[]>([]);
  const [zeitgeistAddress, setZeitgeistAddress] = useState<string>();

  const handleValidAddress = (address: string) => {
    const substrateAddress = encodeAddress(address, 42);
    setZeitgeistAddress(encodeAddress(address, 73));

    const derbyBalance = calculateDerbyBalance(substrateAddress);
    const betaBalance = calculateBetaBalance(substrateAddress);
    const crowdloanBalance = calculateCrowdloan(substrateAddress);
    const shapeTheFutureBalance = calculateShapeTheFuture(substrateAddress);
    const miscBalance = calculateMisc(substrateAddress);

    setBalances([
      {
        title: "Kusama Parachain Derby Rewards (Marketing Campaign #1)",
        amount: derbyBalance,
      },
      {
        title: "Beta Campaign Rewards (Marketing Campaign #2)",
        amount: betaBalance,
      },
      {
        title: "Crowdloan Rewards",
        amount: crowdloanBalance,
      },
      {
        title: "Shape the Future Rewards",
        amount: shapeTheFutureBalance,
      },
      {
        title: "Miscellaneous Rewards",
        amount: miscBalance,
      }
    ]);
  };

  const handleInvalidAddress = () => {
    setZeitgeistAddress("");
    setBalances([]);
  };

  const calculateDerbyBalance = (address: string) => {
    return (
      calculateTotalReward(address, derbyGleamData) +
      calculateTotalReward(address, derbyNodesData) +
      calculateTotalReward(address, derbyTestnetData) +
      calculateTotalReward(address, formCorrectionData)
    );
  };

  const calculateBetaBalance = (address: string) => {
    return calculateTotalReward(encodeAddress(address, 73), betaCampaignData);
  }

  const calculateCrowdloan = (address: string) => {
    return (
      calculateTotalReward(encodeAddress(address, 2), crowdloanBase) +
      calculateTotalReward(encodeAddress(address, 2), crowdloanEarlyBird) +
      calculateTotalReward(encodeAddress(address, 2), crowdloanEarlySupporter) +
      calculateTotalReward(encodeAddress(address, 2), crowdloanReferral) +
      calculateTotalReward(encodeAddress(address, 2), crowdloanSignup) +
      calculateTotalReward(encodeAddress(address, 2), bifrostRewards) +
      calculateTotalReward(encodeAddress(address, 2), bifrostRefRewards)
    );
  }

  const calculateShapeTheFuture = (address: string) => {
    return calculateTotalReward(encodeAddress(address, 0), shapeTheFuturePurchases);
  }

  const calculateMisc = (address: string) => {
    return (
      calculateTotalReward(address, miscRewards) +
      calculateTotalReward(address, seerForChinaRewards.map(({ address, reward }) => ({ address, reward: Number(reward) })))  
    );
  }

  return (
    <>
      <div className="max-w-full h-full	p-7 pt-0 flex flex-col md:h-auto md:absolute md:pt-10">
        <div className="flex items-center flex-col h-2/5 md:flex-row md:h-auto" >
          <img className="mb-10 md:mb-0" src={logo} alt="Zeitgeist logo" width="120" />
          <span className="text-ztg-blue font-bold ml-4 mt-auto text-xl md:mt-0 md:text-lg">
            Check Your Balance
          </span>
        </div>

        <span className="text-white my-4">
          Use this tool to check your allocated balance of ZTG*
        </span>
        <AddressSearch
          onValidAddress={handleValidAddress}
          onInvalidAddress={handleInvalidAddress}
        ></AddressSearch>
        {zeitgeistAddress ? (
          <KeyValueCard title={"Zeitgeist Address"} value={zeitgeistAddress} />
        ) : (
          <></>
        )}
        <div className="my-4">
          <BalanceDetails balances={balances}></BalanceDetails>
        </div>
      </div>
      <div className="text-gray-dark-2 mt-auto mb-2  p-4 text-sm md:text-base md:mb-10">
        *ZTG does not exist yet and will not exist until the genesis block of
        the Zeitgeist network. Beware of anyone offering you ZTG as it is a
        scam.
      </div>
    </>
  );
};

export default BalanceSearch;
