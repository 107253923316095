import { decodeAddress, encodeAddress } from "@polkadot/keyring";
import { hexToU8a, isHex } from "@polkadot/util";
import { ChangeEvent, useState } from "react";

interface AddressSearchProps {
  onValidAddress: (address: string) => void;
  onInvalidAddress: () => void;
}

const AddressSearch = ({
  onValidAddress,
  onInvalidAddress,
}: AddressSearchProps) => {
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>();

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const onSearch = () => {
    if (isValidPolkadotAddress(address) === true) {
      setError("");
      onValidAddress(encodeAddress(address, 42));
    } else {
      setError("Address is invalid");
      onInvalidAddress();
    }
  };

  const isValidPolkadotAddress = (address: string) => {
    try {
      encodeAddress(
        isHex(address) ? hexToU8a(address) : decodeAddress(address)
      );

      return true;
    } catch {
      return false;
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-col md:flex-row">
        <input
          value={address}
          onChange={onAddressChange}
          className="rounded text-black py-3 pl-4 mb-6 md:w-108 md:mb-0"
          placeholder="Polkadot Address"
          type="text"
        />
        <button
          className="rounded-3xl bg-ztg-blue text-white font-bold px-8 py-3 md:ml-5  md:p-y1"
          onClick={onSearch}
        >
          Search
        </button>
      </div>
      <div className="text-red-500 mt-2">{error}</div>
    </div>
  );
};

export default AddressSearch;
